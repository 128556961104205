import { defineStore } from 'pinia'

export const useMainStore = defineStore('main', {
  state: () => ({
    name: 'Eddy Mhalli',
    picture: './photo.webp',
    headline: '💻 Digital Fridge Leader 🌍 Typescript universe',
    summary: `
      <b>Digital Fridge Leader</b>....? You know this famous machine used, almost, by the whole world. Where fruits, vegetables and other perishable foods can be store and used, cooked and consumed.
      You also know the comfort to go to the restaurant, let these talented cooks preparing some delicious dishes for you. 
      My role is exactly the same, to know what products to use, to know the associations, to investigate the reliability and to orchestrate the mixtures.
      Having a strong taste for my job, my role is to select the right products, to prepare the combination and cook these products for you. Because <b>I am a Digital Fridge Leader</b>.`,
    languages: [
      {
        code: 'fr',
        picture: './flag-french.png',
      },
      {
        code: 'en',
        picture: './flag-english.png',
      },
    ],
    externalLinks: [
      {
        link: 'tel:+33627036969',
        color: 'green lighten-1',
        icon: 'mdi-whatsapp',
        shortName: '+33 6 27 03 69 69',
      },
      {
        link: 'mailto:eddy.mhalli@gmail.com',
        color: 'pink accent-4',
        icon: 'mdi-gmail',
        shortName: 'eddy.mhalli@gmail.com',
      },
      {
        link: 'https://fr.linkedin.com/in/eddy-mhalli-232527a7',
        color: 'blue lighten-1',
        icon: 'mdi-linkedin',
        shortName: 'Eddy Mhalli',
      },
      {
        link: 'https://github.com/dobobaie',
        color: 'blue-grey lighten-3',
        icon: 'mdi-github',
        shortName: 'dobobaie',
      },
    ],
    info: [
      {
        icon: 'mdi-flag',
        shortName: 'French',
      },
      {
        icon: 'mdi-account',
        shortName: '🎂 30 yo',
      },
      {
        icon: 'mdi-map-marker',
        shortName: 'Annecy / Genève',
      },
    ],
    experiences: [
      {
        link: 'http://urbyn.io/',
        name: 'Urbyn.io',
        status: 'Lead developer & lead engineer',
        logo: '',
        start_date: '01/2024',
        end_date: '08/2024',
        locality: 'Remote',
        short_description: 'Urbyn help companies in their recycling process and tracking. My job was literally create automates to automatize some process including the use of modern approach to calculate, predict, fix and anticipate data.',
        description: '',
        pins: [
          'typescript',
          'nestjs',
          'postgresql',
          'graphql',
          'kafka',
          'docker',
          'etl',
          'bubble',
          'more',
        ],
        skills: [
          'Typescript (nest.js)',
          'Postgresql',
          'Graphql',
          'Kafka',
          'Docker',
        ],
      },
      {
        link: 'http://eddy.mhalli.fr/',
        name: 'Freelance',
        status: 'Lead fullstack developer & IT Consultant',
        logo: '',
        start_date: '01/2019',
        end_date: '07/2024',
        locality: 'Hô-Chi-Minh, Vietnam',
        short_description:
          'Developping, consulting, supervasing and leading few projects around the world. Ex realization: platform e-learning, products catalogue, food/stuffs delivery process, tools & micro-services.',
        description:
          '<p>Creation and project support: TokenVu, FlavorBox, Marketoi, Laprerentrée, Yummmy, etc...</p><p>As Fullstack Freelancer, my Job was to create few projects from scratch, to help and support existing projects, during all the project development. Plus help and shape the development team in the compagnies.</p>',
        pins: [
          'nodejs',
          'vuejs',
          'nuxt',
          'react',
          'docker',
          'ionic',
          'postgresql',
          'rabbitmq',
          'redis',
          'aws',
          'more',
        ],
        skills: [
          'NodeJs (koa)',
          'Docker',
          'PostgreSQL',
          'RabbitMQ',
          'Redis',
          'Google services',
          'MongoDB',
        ],
      },
      {
        link: 'http://swan.io/',
        name: 'Swan.io',
        status: 'Lead engineer & Backend developer',
        logo: '',
        start_date: '06/2021',
        end_date: '09/2023',
        locality: 'Paris, France',
        short_description:
          'Swan is a B2B neobank. My first team was End Customer team by leading Ultimate Beneficiary Owner, leading Capital Deposit and finally leading Closing Account. My second team was User team, my role was to refacto few projects by using Clean Archi/Code + Hexagonal pattern, then leading Server Consent and then joining German KYC. More and more, just ask me :)',
        description: '',
        pins: [
          'typescript',
          'nestjs',
          'fp-ts',
          'postgresql',
          'graphql',
          'kafka',
          'docker',
          'more',
        ],
        skills: [
          'Typescript (nest.js + FP-TS)',
          'Postgresql',
          'Graphql',
          'Kafka',
          'Docker',
        ],
      },
      {
        link: 'http://artips.fr/',
        name: 'Artips',
        status: 'Lead fullstack Developer',
        logo: '/assets/photos/artips.jpg',
        start_date: '03/2020',
        end_date: '01/2021',
        locality: 'Paris, France',
        short_description: `Projects's process setting up, team leader, technical referent, projects supervisor, developping and projects architecture improvement + others cool projects.`,
        description:
          '<p>Creation, restructuring, micro-service maintenances and CLI applications.</p><p> My work: architecture implementation, creation of various services/CLI and cleaning/optimization code debt.</ p>',
        pins: [
          'nodejs',
          'vuejs',
          'nuxt',
          'angularjs',
          'docker',
          'mongodb',
          'postgresql',
          'rabbitmq',
          'redis',
          'firebase',
          'more',
        ],
        skills: [
          'NodeJs (koa)',
          'Docker',
          'PostgreSQL',
          'RabbitMQ',
          'Redis',
          'TestRails',
          'Google services',
          'MongoDB',
          'Telegram bot',
        ],
      },
      {
        link: 'http://fearless-vr.com/',
        name: 'Fearless VR',
        status: 'Co-founder and fullstack developer',
        more: 'End of studies project',
        logo: '/assets/photos/FEARLESS.png',
        start_date: '01/2015',
        end_date: '01/2018',
        locality: null,
        short_description:
          'Team leader & main developer. Creation of multiple app with socket system, reality virtual, using Unity as well, few backoffices, desktop and mobiles app, etc. Winner of Medical Innovation Incubator by Viva Tech 2017.',
        description:
          '<p>Fearless is software that uses virtual reality to help people with physichic disorders such as phobias, addictions, stress and blockages, through interactive 3D scenes in which patient can immerse and surpass his virtual limits to better manage them in real life.</p><p>My job at Fearless is to verify the vision of our project and to establish and update our schedules and budgets. Apart development, I also meet specialists and communicate with them and I remain attentive to each proposal that would improve the lives of its people.</p>',
        pins: [
          'nodejs',
          'angularjs',
          'docker',
          'ionic',
          'postgresql',
          'csharp',
          'redis',
          'aws',
          'unity',
          'vr',
          'more',
        ],
        skills: [
          'Project Management',
          'Architectural designer',
          'Angular 1',
          'Angular 4',
          'TypeScript',
          'NodeJs (es 6)',
          'Express',
          'Socket.IO',
          'MeteorJs',
          'DevOps',
          'C# Unity',
        ],
      },
      {
        link: 'http://trusk.com/',
        name: 'Trusk',
        status: 'Node.Js & tools developer',
        logo: '/assets/photos/trusk.jpg',
        start_date: '01/2018',
        end_date: '01/2019',
        locality: 'Paris, France',
        short_description:
          'Creation multiple micro-services, in charge of developing few app for delays/optimization/organization tours. Development of a new code base and project architectural.',
        description:
          '<p>Creation, restructuring, micro-service maintenances and CLI applications.</p><p> My work: architecture implementation, creation of various services/CLI and cleaning/optimization code debt.</ p>',
        pins: [
          'nodejs',
          'react',
          'docker',
          'mongodb',
          'postgresql',
          'rabbitmq',
          'redis',
          'aws',
          'google-cloud',
        ],
        skills: [
          'NodeJs (koa)',
          'Docker',
          'PostgreSQL',
          'RabbitMQ',
          'Redis',
          'TestRails',
          'Google services',
          'MongoDB',
          'Telegram bot',
        ],
      },
      {
        link: '',
        name: 'Corporate worker, internship, freelance',
        status: 'Fullstack / mobile developer, devops, etc.',
        logo: '/assets/photos/SEITOSEI.png',
        start_date: 'from 08/2008',
        end_date: 'to 08/2016',
        locality: 'Paris, France',
        short_description: 'Matters, Bel Air, SEITOSEI, RS2I, Job & Musik, ... much more.<br/>First experience, first projects, first clients, thousand github projects, a few freelance projects, and others.<br/>Ask me and let\'s discuss about it :)',
        description: '',
        pins: [],
        skills: [
          'Jquery',
          'AngularJs (v1)',
          'Ionic (v1)',
          'Bootstrap (scss)',
          'Php (laravel)',
          'NodeJs (express)',
          'Npm (gulp)',
          'Gitlab',
          'xCode',
          'Android (store)',
          'iOs (store)',
          'Autoit',
          'Webradio',
          'Windows phone (store)',
          'SVN',
          'Delphi',
          'MySQL',
        ],
      },
    ],
    formations: [
      {
        name: 'EPITECH',
        status: 'Information Technology Expert',
        locality: 'Paris, France',
        logo: '/assets/photos/EPITECH.png',
        start_date: '01/2013',
        end_date: '01/2018',
        description: '',
        degrees: [
          {
            name: 'Master in Information Technology - BAC +5',
          },
          {
            name: 'Bachelor in Information Technology - BAC +3',
          },
        ],
      },
      {
        name: 'Laval university',
        status: 'Bachelor in Software Engineering - BAC +4',
        locality: 'Quebec City, Canada',
        more: 'Exchange program',
        logo: '/assets/photos/ULAVAL.jpg',
        start_date: '01/2016',
        end_date: '01/2017',
        description: '',
        degrees: [
          {
            name: 'Certificate in software engineering - BAC +4',
          },
        ],
      },
      {
        name: 'High School parc de Vilgénis',
        status: 'Professional baccalaureate in digital electronic systems',
        locality: 'Massy, France',
        logo: '/assets/photos/VILGENIS.png',
        start_date: '01/2010',
        end_date: '01/2013',
        description: '',
        degrees: [
          {
            name: 'Professional baccalaureate in digital electronic systems',
          },
          {
            name: 'Patent of professional studies digital electronic systems',
          },
        ],
      },
    ],
    mainProgrammingTools: [
      'typescript',
      'nestjs',
      'graphql',
      'kafka',
      'nodejs',
      'vuejs',
      'docker',
      'postgresql',
      'redis',
      'rabbitmq',
    ],
    hobbit: {
      description:
        'and many more... Like movies, cooking, sharing and of course coding ❤️',
    },
    hobbies: [
      {
        icon: 'mdi-boxing-glove',
        name: 'Boxing',
        short_description: '',
      },
      {
        icon: 'mdi-diving-scuba',
        name: 'Diving',
        short_description: '',
      },
      {
        icon: 'mdi-puzzle-outline',
        name: 'Problem solving',
        short_description: '',
      },
      {
        icon: 'mdi-airplane-takeoff',
        name: 'Cultural diversity',
        short_description: '',
      },
      {
        icon: 'mdi-sprout',
        name: 'Biology',
        short_description: '',
      },
      {
        icon: 'mdi-flask-outline',
        name: 'Physics',
        short_description: '',
      },
      {
        icon: 'mdi-file-document-edit-outline',
        name: 'Writing',
        short_description: '',
      },
      {
        icon: 'mdi-account-group-outline',
        name: 'Impro',
        short_description: '',
      },
    ],
    projects: [{
      name: "Chop-Chop",
      short_description: "Marketplace platform - TikTok shop like - made from Vue.js, Nest like & Firebase",
      pins: [],
    }, {
      name: "Nagory",
      short_description: "Inventory & Kitchen manager - made from Vue.js, Strapi & Electron",
      pins: [],
    }, {
      name: "Firefly",
      short_description: "Backend framework - communication balancer (RPC, socket, HTTP, etc.) - made from raw Node.js",
      pins: [],
    }, {
      name: "EasyAuth",
      short_description: "Backend CMS / tools - Custom credentials manager (including oauth) - made from Nuxt.js",
      pins: [],
    }, {
      name: "SpeedUp",
      short_description: "Scraper - Including custom pattern - made from Vue.js, Node.js & Electron",
      pins: [],
    }, {
      name: "And many more... =)",
      short_description: "",
      pins: [],
    }],
    personalExploration: [{
      name: "Ask me about Chop-Chop, Nagory, HopHup, Firefly, etc. =)",
      short_description: "",
      pins: [],
    }, {
      name: "Ask me about Chop-Chop, Nagory, HopHup, Firefly, etc. =)",
      short_description: "",
      pins: [],
    }]
  }),
});
